<template>
  <b-card class="text-left mb-2">
    <div class="props-card_header">
      <div @click="close" class="props-card_close"></div>
    </div>

    <div class="mt-2">
      <div class="row">
        <div class="col-12">
          <h5>{{data.name}}</h5>
          <div role="group" class="soap-input">

            <b-form-input id="input-live-101" v-model="editedName"></b-form-input>
          </div>

        </div>
      </div>
       <div class="row equal">
        <div class="col-4">
          <div role="group" class="soap-input">
            <label class="text-center" for="input-live-6"
              ><small>
                %
              </small></label
            >
<!--            :disabled="mass !== 'percent'"-->
            <b-form-input
              @keyup="calculate"
              type="number"
              v-model.number="percent"
              id="input-live-6"
              min="0"
              max="100"
            ></b-form-input>
          </div>
        </div>

        <div class="col-4">
          <div role="group" class="soap-input">
            <label class="text-center" for="input-live-7"
              ><small>г</small></label
            >
<!--            :disabled="mass !== 'gram'"-->
            <b-form-input
              @keyup="calculateMas"
              type="number"
              v-model.number="gram"
              id="input-live-7"
              min="0"
            ></b-form-input>
          </div>
        </div>


      </div>
    </div>

  </b-card>
</template>

<script>
//import VueRangeSlider from "vue-range-component";
// import RangeSlider from "vue-range-slider";
// import "vue-range-slider/dist/vue-range-slider.css";

export default {
  components: {
    // VueRangeSlider
    // RangeSlider
  },
  props: {
    param: null,
    mass: null,
    masValue: null,

  },
  data() {
    return {
      percent: 0,
      data: this.$props.param,
      editedName: this.$props.param.name,
      gram: 0
    };
  },
  watch: {

    data: {
      handler: function(val) {
        this.$emit("update:param", val);
      },
      deep: true
    },
    masValue() {
      this.calculate();
      this.calculateMas();
    },
    percent(val) {
        this.data.percent = val;
    },
    gram(val) {
        this.data.gram = val;
    },
    editedName(val) {
        this.data.editedName = val;
        /* eslint-disable no-console */
        console.log("editedName", this.data);
    }
  },

  methods: {
    close() {
      this.$emit("cls");
    },
    calculate() {
      /* eslint-disable no-console */
      if (this.percent > 100) {
          this.percent = 100;
        // setTimeout(() => {
        //   this.percent = 100;
        // }, 1);
      }
      this.gram = this.masValue * this.percent/100;
       this.$set(this.data, 'percent', this.percent)
      if(this.percent) this.percent = +this.percent.toFixed(2);
    },

     calculateMas() {
       if (this.gram < 0) {
          this.gram = 0;
      }
      /* eslint-disable no-console */

      this.percent = (+this.gram * 100) / +this.masValue;
      if(isNaN(this.percent)) this.percent = 0;
      this.$set(this.data, 'percent', this.percent)
      console.log(this.percent, +this.gram, +this.masValue)
      if(this.percent) this.percent = +this.percent.toFixed(2);
    }
  },

  created() {
       this.$set(this.data, 'percent', this.percent);
       this.$set(this.data, 'gram', this.gram);
       this.$set(this.data, 'editedName', this.editedName)
  }
};
</script>

<style></style>
